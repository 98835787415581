import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
          <path d="M4573 6940 c-72 -13 -175 -54 -226 -89 -103 -70 -174 -162 -220 -284
-30 -78 -31 -89 -31 -232 -1 -82 1 -163 4 -179 l5 -28 -87 -3 c-84 -2 -88 -1
-88 19 0 29 -53 119 -85 144 -25 20 -33 42 -16 42 5 0 27 17 48 38 56 54 78
111 77 202 -1 176 -80 275 -261 327 -47 13 -123 17 -399 20 -308 5 -342 3
-357 -12 -14 -13 -17 -37 -17 -130 0 -126 8 -145 61 -145 l29 0 0 -310 0 -310
-29 0 c-60 0 -61 -1 -61 -134 0 -82 4 -126 12 -134 17 -17 564 -17 574 -1 4 7
9 42 12 80 l5 67 31 -45 c60 -89 155 -133 287 -133 172 0 264 91 276 271 l6
82 33 -64 c92 -184 261 -285 495 -296 307 -16 515 108 607 360 23 64 26 90 29
232 4 139 1 170 -17 235 -30 108 -30 105 9 105 35 0 36 -1 178 -238 l143 -238
0 -75 0 -74 -29 0 c-53 0 -61 -19 -61 -139 0 -70 4 -113 13 -124 11 -16 38
-17 292 -15 l280 3 3 132 c3 146 8 135 -63 145 -17 3 -20 12 -23 69 l-3 65
104 179 c57 99 121 210 141 248 37 67 38 67 81 67 30 0 45 5 49 16 3 9 6 67 6
129 0 103 -2 115 -19 125 -26 13 -436 13 -462 0 -17 -10 -19 -22 -19 -125 0
-127 4 -145 31 -145 10 0 19 -5 19 -11 0 -12 -69 -139 -76 -139 -2 0 -19 31
-39 69 l-34 69 24 16 c24 16 25 20 25 136 0 108 -2 120 -19 130 -29 15 -573
14 -589 -2 -8 -8 -12 -47 -12 -109 0 -92 -11 -133 -24 -91 -4 10 -33 45 -65
78 -66 66 -149 113 -250 139 -76 20 -238 27 -308 15z m-1035 -336 c26 -29 31
-107 8 -130 -20 -19 -61 -34 -97 -34 l-29 0 0 101 0 100 48 -6 c32 -5 54 -15
70 -31z m1214 -14 c44 -48 62 -127 62 -270 0 -192 -40 -290 -118 -290 -40 0
-66 21 -93 75 -79 156 -43 477 57 518 27 11 64 -3 92 -33z m-1279 -423 c13 -9
19 -33 24 -85 l6 -72 -42 0 -41 0 0 85 c0 75 2 85 18 85 10 0 26 -6 35 -13z"/>
<path d="M6596 6898 c-12 -17 -16 -48 -16 -123 1 -99 11 -135 39 -135 22 0 15
-29 -142 -525 l-32 -100 -35 -3 c-85 -6 -80 2 -80 -135 0 -67 4 -128 8 -135 7
-9 71 -12 288 -10 l279 3 3 132 c3 148 8 138 -73 145 -16 2 -29 8 -27 13 1 6
55 10 137 10 83 0 135 -4 135 -10 0 -5 -23 -11 -52 -13 -35 -2 -57 -8 -65 -19
-14 -18 -18 -198 -7 -241 l6 -22 827 2 826 3 3 229 c2 160 -1 233 -9 242 -16
19 -287 20 -312 2 -13 -10 -17 -33 -19 -103 l-3 -90 -45 -1 c-87 -3 -80 -31
-80 312 l0 304 24 0 c55 0 56 2 56 139 0 85 -4 131 -12 139 -9 9 -83 12 -273
12 -190 0 -264 -3 -273 -12 -8 -8 -12 -54 -12 -139 0 -137 1 -139 56 -139 l24
0 -2 -307 -3 -308 -40 -5 c-22 -3 -65 -3 -95 0 l-55 5 -22 75 c-12 41 -38 125
-57 185 -19 61 -71 227 -116 370 -44 143 -86 263 -93 267 -7 4 -155 8 -329 8
-316 0 -317 0 -332 -22z m373 -490 c31 -92 30 -98 -24 -98 -27 0 -45 5 -45 11
0 16 40 149 45 149 2 0 13 -28 24 -62z"/>
<path d="M7672 5340 c-106 -15 -230 -91 -291 -180 -43 -62 -66 -174 -52 -251
19 -113 76 -194 179 -255 31 -19 55 -37 52 -40 -3 -3 -56 -7 -118 -8 -140 -1
-142 -3 -142 -124 0 -73 -2 -83 -16 -78 -9 3 -33 6 -54 6 -38 0 -39 1 -112
115 -40 63 -103 161 -140 218 -37 56 -68 109 -68 118 0 8 41 50 91 94 86 74
94 78 141 80 30 1 53 7 59 16 5 8 9 67 9 131 0 106 -2 118 -19 128 -12 6 -123
10 -290 10 -232 0 -272 -2 -285 -16 -13 -12 -16 -39 -16 -129 0 -101 2 -116
20 -133 20 -18 19 -19 -44 -75 -36 -31 -67 -57 -71 -57 -3 0 -5 28 -3 63 3 52
6 62 20 59 10 -2 27 2 38 8 18 10 20 21 20 133 0 82 -4 127 -12 135 -17 17
-529 17 -546 0 -9 -9 -18 -9 -37 0 -18 8 -104 12 -269 12 -223 0 -244 -2 -259
-18 -14 -15 -17 -40 -17 -129 0 -125 5 -135 68 -138 37 -1 37 -2 40 -48 2 -25
2 -70 0 -99 l-3 -53 -153 230 c-85 127 -161 236 -170 243 -20 15 -502 18 -502
2 0 -6 -9 -6 -26 0 -14 6 -144 10 -294 10 -231 0 -271 -2 -284 -16 -13 -12
-16 -39 -16 -129 0 -128 8 -145 66 -145 l34 0 -2 -307 c-3 -300 -4 -308 -23
-309 -11 -1 -31 -3 -45 -5 l-25 -4 -3 94 c-1 52 -8 101 -14 108 -8 10 -49 13
-157 13 -80 0 -151 -4 -158 -8 -8 -5 -13 -42 -15 -102 l-3 -95 -40 -3 c-22 -1
-50 0 -62 3 l-23 5 0 305 0 305 24 0 c55 0 56 2 56 141 0 117 -2 129 -19 139
-12 6 -118 10 -275 10 -218 0 -257 -2 -270 -16 -13 -12 -16 -39 -16 -133 0
-129 5 -141 57 -141 l33 0 0 -305 c0 -168 -3 -305 -7 -306 -16 -1 -41 -7 -62
-14 -20 -6 -21 -13 -21 -128 0 -67 5 -129 10 -137 8 -13 73 -15 471 -18 337
-2 465 1 475 9 11 9 21 9 42 0 20 -9 106 -11 298 -7 148 2 278 4 289 4 11 0
136 -1 278 -2 219 -1 259 1 267 14 5 8 10 68 10 132 0 133 -3 138 -79 138
l-41 0 0 105 c0 58 3 105 8 105 4 -1 79 -109 166 -241 88 -132 168 -245 177
-250 10 -5 94 -9 188 -9 158 0 173 2 191 20 20 20 20 32 20 451 -1 238 2 434
5 438 10 9 90 14 150 9 l30 -3 0 -310 0 -309 -34 -7 c-18 -4 -37 -13 -42 -21
-5 -7 -9 -63 -9 -125 0 -104 1 -113 22 -127 19 -14 63 -16 278 -16 186 0 259
3 268 12 8 8 12 54 12 139 0 137 -1 139 -56 139 -24 0 -25 2 -22 61 3 56 6 64
35 86 l32 25 51 -78 c27 -44 50 -82 50 -86 0 -5 -10 -8 -23 -8 -41 0 -47 -17
-47 -141 0 -94 3 -121 16 -134 16 -16 47 -16 689 -7 11 1 86 -2 167 -4 159 -5
188 1 188 40 0 35 9 37 50 10 170 -113 457 -91 614 47 23 20 55 61 71 90 39
72 47 200 19 281 -26 73 -107 152 -209 203 l-80 40 115 2 c63 2 125 5 138 8
l22 5 0 229 c0 199 -2 230 -16 235 -9 3 -80 6 -159 6 -117 0 -146 -3 -159 -16
-9 -8 -16 -22 -16 -30 0 -8 -7 -14 -15 -14 -8 0 -15 4 -15 8 0 11 -84 49 -135
61 -66 15 -146 19 -213 11z m-2907 -304 l30 -1 3 -312 2 -313 -37 -2 c-49 -2
-70 -2 -113 4 l-35 5 -3 307 -2 306 39 0 c22 0 43 5 46 11 4 6 14 7 24 3 9 -3
30 -7 46 -8z m3202 -36 c38 -23 60 -55 69 -98 5 -25 3 -32 -9 -32 -31 0 -182
64 -205 87 -23 23 -23 25 -6 44 24 26 107 26 151 -1z m-241 -440 c16 -5 68
-21 117 -35 69 -21 87 -30 87 -45 0 -23 -26 -32 -89 -32 -90 1 -161 48 -161
107 0 18 7 18 46 5z"/>
<path d="M7870 3986 c-73 -17 -143 -50 -186 -88 -19 -17 -36 -29 -38 -27 -1 2
-7 24 -12 49 l-9 45 -142 3 c-141 3 -142 2 -145 -20 -2 -13 -2 -105 0 -205 l3
-183 149 0 c133 0 150 2 150 17 0 9 11 35 25 59 31 53 92 79 182 78 128 -1
167 -83 60 -126 -23 -10 -85 -29 -137 -44 -136 -38 -245 -91 -306 -150 -56
-53 -77 -93 -95 -182 -11 -51 -10 -64 11 -131 21 -65 33 -83 86 -138 55 -55
68 -63 101 -64 21 0 31 -3 23 -6 -28 -11 86 -33 173 -34 117 0 191 27 259 95
27 26 50 47 52 45 12 -19 26 -65 26 -88 l0 -29 93 4 c50 3 106 7 122 9 22 4
26 2 15 -5 -13 -8 -13 -10 1 -10 9 0 19 7 22 15 5 11 10 246 7 353 0 22 -2 22
-140 22 l-139 0 -7 -37 c-17 -93 -90 -148 -196 -148 -49 0 -61 4 -87 29 -34
33 -39 60 -15 94 21 30 66 52 224 107 210 73 287 118 347 202 43 61 57 113 50
190 -15 161 -144 275 -343 303 -92 12 -114 12 -184 -4z m-185 -1116 c3 -6 -1
-7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m218 3 c-7 -2 -19 -2 -25 0 -7
3 -2 5 12 5 14 0 19 -2 13 -5z m-53 -13 c0 -12 -8 -12 -35 0 -18 8 -17 9 8 9
15 1 27 -3 27 -9z"/>
<path d="M4698 3974 c-8 -7 3 -207 11 -216 3 -4 8 45 9 108 3 103 -1 127 -20
108z"/>
<path d="M4741 3965 c-3 -5 -6 -17 -5 -25 1 -8 2 -55 3 -103 l1 -89 40 5 c22
2 42 2 44 -2 2 -3 5 -161 7 -351 l4 -345 -42 -3 -43 -3 0 -89 c0 -82 11 -118
29 -94 3 5 91 6 196 3 105 -3 201 -2 215 2 14 4 22 8 18 8 -5 1 0 9 10 19 29
31 62 72 62 77 0 8 52 88 65 100 5 6 42 57 80 115 38 58 86 128 107 156 21 28
38 56 38 62 0 7 5 12 10 12 6 0 10 -68 10 -185 l0 -185 -49 0 c-27 0 -51 -4
-53 -10 -1 -5 -2 -48 0 -95 3 -72 6 -85 20 -84 11 0 12 2 5 6 -7 2 -12 17 -12
31 1 21 2 23 6 7 3 -11 9 -24 14 -29 12 -13 399 -14 399 -1 0 6 4 3 10 -5 16
-26 24 -1 28 91 4 84 3 91 -14 89 -11 -1 -35 -2 -54 -1 l-36 1 4 460 4 460
-174 0 -174 0 -25 -32 c-25 -34 -129 -190 -129 -194 0 -7 -145 -220 -152 -222
-4 -2 -8 -8 -8 -13 0 -7 -51 -89 -82 -134 -4 -5 -8 78 -8 185 l0 195 59 -2 59
-2 5 77 c3 42 5 91 4 107 l-2 30 -243 5 c-157 3 -246 1 -251 -5z"/>
<path d="M6630 3868 c0 -107 0 -108 25 -114 14 -3 37 -4 50 -1 22 4 25 2 19
-17 -4 -11 -22 -46 -42 -76 -19 -30 -50 -79 -69 -107 -18 -29 -37 -53 -42 -53
-5 0 -32 21 -60 46 l-51 46 0 71 c0 40 4 77 8 82 4 6 23 10 42 10 l35 0 3 108
3 107 -256 0 -256 0 3 -107 3 -108 34 -2 c19 -2 36 -5 38 -6 2 -2 6 -160 10
-350 l6 -347 -41 0 -42 0 0 -92 0 -93 238 0 c153 -1 240 3 244 10 4 5 8 47 8
92 l0 82 -42 3 -43 3 2 120 c2 66 6 121 11 123 5 1 51 -34 103 -78 52 -45 113
-97 136 -117 23 -20 40 -40 37 -44 -3 -5 -28 -9 -56 -9 l-50 0 3 -84 c2 -46 7
-87 11 -91 10 -11 478 -12 478 -1 0 5 7 3 15 -4 13 -11 15 -1 15 84 l0 96 -42
0 c-37 0 -50 7 -91 43 -73 67 -148 131 -163 140 -26 15 -33 3 193 345 110 166
116 172 148 172 64 0 65 2 65 116 0 57 -3 104 -7 104 -5 0 -149 1 -321 3
l-312 2 0 -107z"/>
<path d="M3165 3943 c-3 -15 -3 -65 0 -110 l7 -83 38 0 37 0 6 -106 c3 -59 5
-215 4 -348 -2 -206 -4 -241 -17 -242 -8 -1 -28 -2 -43 -2 l-28 -2 3 -95 c2
-70 6 -95 16 -95 7 0 11 3 9 8 -3 4 98 5 225 2 l230 -5 10 24 c5 14 8 56 6 95
l-3 71 -38 0 c-26 0 -37 4 -37 15 0 8 0 161 0 340 0 244 4 326 13 332 6 4 51
7 99 6 l87 -1 3 -96 3 -96 135 0 135 0 3 208 2 207 -449 0 -449 0 -7 -27z"/>
<path d="M4098 3963 c-2 -5 -1 -53 2 -108 l6 -100 2 108 c2 97 -1 121 -10 100z"/>
<path d="M4136 3962 c-3 -5 -3 -17 -1 -28 2 -10 4 -56 4 -101 l1 -83 50 0 50
0 0 -345 0 -345 -34 0 c-61 0 -61 -1 -58 -103 l3 -92 249 5 c136 3 246 2 243
-2 -2 -5 0 -8 6 -8 16 0 22 38 19 120 l-3 75 -48 -2 -47 -1 2 346 3 347 47 3
47 3 3 92 c2 51 1 100 -1 110 -3 16 -24 17 -267 17 -144 0 -265 -4 -268 -8z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
